import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid, GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import { useInvoicePreColumns } from '../../constants';
import { useIsMobile } from '../../hooks';
import { getTimeDiffText } from '../../helpers';

const COLUMNS = [
  'folio',
  'dateIssued',
  'receiver_Name',
  'receiver_Rut',
  'amountWithIva',
  'orderingMonthlyRate',
  'orderingDefaultRate',
];

const statusToLabel = {
  CREATED: 'Evaluando facturas',
  ASSIGNED: 'Procesando facturas',
  FINISHED: 'Operación finalizada',
  PENDINGCOLLECTION: 'En recaudación',
  PROCESSINGINVOICES: 'Evaluando facturas',
};

const OrderingOperationPaymentSummary = ({ purchaseOrder }) => {
  const isFactoring = !!purchaseOrder.invoiceAssignationRecords.length;
  const assignations = purchaseOrder.invoiceAssignationRecords;
  const invoices = assignations.map((assignation) => assignation.invoice);
  const operationStatus = assignations?.[0]?.invoiceAssignationOperation?.status?.name || 'Finished';
  const statusLabel = isFactoring
    ? statusToLabel[operationStatus.toUpperCase()]
    : statusToLabel[purchaseOrder.status.toUpperCase()];
  const isMobile = useIsMobile();
  const columns = [
    ...useInvoicePreColumns().filter((col) => COLUMNS.includes(col.field)),
    {
      field: 'orderingDateToPay',
      headerName: isMobile ? 'Fecha' : 'Fecha de pago',
      ...GRID_DATE_COL_DEF,
      width: isMobile ? 93 : 175,
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const selectedAssignation = assignations.find(
          (assignation) => assignation.invoice.id === row.id,
        );
        const dateToPay = row.dateToPay?.isValid()
          ? row.dateToPay
          : moment(selectedAssignation.dateToPay);
        return (
          <Grid container justifyContent="center" alignItems="flex-start" direction="column">
            <Typography variant="body2">{dateToPay.format(isMobile ? 'DD MMM' : 'DD-MM-YYYY')}</Typography>
            {!isMobile && (
            <Typography variant="caption">{getTimeDiffText(dateToPay)}</Typography>
            )}
          </Grid>
        );
      },
    },
  ];

  return (
    <Box bgcolor="white" sx={{ m: 1, p: 2, borderRadius: 5, width: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} pl={1} pr={2}>
        <Typography variant="body2">
          Pagado con: <strong>{isFactoring ? 'Factoring' : 'Transferencia bancaria'}</strong>
        </Typography>
        <Typography variant="body2">Estado: <strong>{statusLabel}</strong></Typography>
      </Stack>
      {isFactoring ? (
        <Box sx={{ height: invoices.length > 1 ? 238 : 162 }}>
          <DataGrid
            rows={invoices}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            density="compact"
          />
        </Box>
      ) : null}
    </Box>
  );
};

OrderingOperationPaymentSummary.propTypes = {
  purchaseOrder: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default OrderingOperationPaymentSummary;
